import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FileUploader, FileItem} from "ng2-file-upload";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

    public uploader: FileUploader;
    public maxFileSize;
    public maxFileSizeTotal;
    public allowedMimeTypes;
    public maxAllowedFiles;
    public fileErrors: Array<string> = [];
    
    // upload properties
    @Input() UPLOAD_INFO: String;
    @Input() FILE_EXT_WHITELIST : String;
    @Input() UPLOAD_FILES_MAX : String;
    @Input() UPLOAD_FILE_MAX_SIZE : String;
    @Input() UPLOAD_FILE_MAX_SIZE_TOTAL : String;
    @Input() VIRUS_INFO_MSG: String;
    @Input() UPLOAD_ERR_MSG: String;
    
    @Output() uploadQueueChange = new EventEmitter();
    
    constructor(private translate: TranslateService) {}

    ngOnInit() {
        
        this.maxFileSize      = this.UPLOAD_FILE_MAX_SIZE;
        this.maxAllowedFiles  = this.UPLOAD_FILES_MAX;
        this.allowedMimeTypes = this.FILE_EXT_WHITELIST.split(',').map(string => string.trim())
        this.maxFileSizeTotal = this.UPLOAD_FILE_MAX_SIZE_TOTAL;
        
        console.log("maxFileSize: " + this.maxFileSize);
        console.log("maxAllowedFiles: " + this.maxAllowedFiles);
        console.log("maxFileSizeTotal: " + this.maxFileSizeTotal);
        console.log("allowedMimeTypes: " + this.allowedMimeTypes);
        
        
        this.uploader = new FileUploader( {
            url: 'NONE',
            isHTML5 : true,
            //authToken: 'Bearer ' + localStorage.getItem('token'),
            allowedMimeType: this.allowedMimeTypes,
            autoUpload: false,
            removeAfterUpload: true,
            maxFileSize: this.maxFileSize,
            queueLimit : this.maxAllowedFiles
        
        } );
        
        
        
        // HANDLING VON UPLOAD ERRORS
        this.uploader.onWhenAddingFileFailed = (item, filter) => {
            console.log("filtername: " + filter.name);
            
            switch (filter.name) {
              case 'fileSize':
                  this.translate.get('size_limit', {"0": this.formatBytes(item.size), "1" : this.formatBytes(this.maxFileSize)}).subscribe((res: string) => {
                      this.fileErrors.push(item.name + " : " + res);
                  });
                  break;
              case 'mimeType' :
                  this.translate.get('wrong_mime_type').subscribe((res: string) => {
                      this.fileErrors.push(item.name + " : " + res);
                  });
                break;
              case 'queueLimit' :
                  this.translate.get('file_count_limit', {"0": this.maxAllowedFiles}).subscribe((res: string) => {
                      this.fileErrors.push(item.name + " : " + res);
                  });
                break;
              default:
                  //message = 'Error trying to upload file '+item.name;
                break;
            }
          };
          
          /* Überprüfen ob die Neue Datei UPLOAD_FILE_MAX_SIZE_TOTAL überschreitet */ 
          this.uploader.onAfterAddingFile = (item: FileItem) => {
              console.log("onAfterAddingFile...");
              console.log(item.file.name + " - " + item.file.size);
              
              // get current size from all the files in the queue
              var sum=0;
              this.uploader.queue.filter(elem => sum = sum + elem.file.size);
              console.log("current size all files: " + sum);
              if (sum > this.maxFileSizeTotal) {
                  this.translate.get('total_size_limit', {"0": this.formatBytes(sum), "1" : this.formatBytes(this.maxFileSizeTotal)}).subscribe((res: string) => {
                      this.fileErrors.push(item.file.name + " : " + res);
                  });
                  this.uploader.removeFromQueue(item);
              }
          };
    }
    
    public onFileDropped(event) {
        console.log("onFileDropped");
        this.uploadQueueChange.emit();
    }
    
    public onFileSelected(event) {
        console.log("onFileSelected");
        this.uploadQueueChange.emit();
    }
    
    public clearQueue() {        
        this.uploader.clearQueue();
        this.uploadQueueChange.emit();
    }
    
    public clearQueueItem(item: FileItem) {
        item.remove();
        this.uploadQueueChange.emit();
    }
    
    public isQueueEmpty() {
        var result;
        if (this.uploader && this.uploader.queue.length > 0) 
            result = false;
        else
            result =  true;
        
        console.log("isQueueEmpty : " + result);
        return result;
    } 
    
    
    formatBytes( bytes, decimals?) {
        if ( bytes == 0 ) return '0 Bytes';
        const k = 1024,
            dm = decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor( Math.log( bytes ) / Math.log( k ) );
        return parseFloat(( bytes / Math.pow( k, i ) ).toFixed( dm ) ) + ' ' + sizes[i];
     }

    public addFileErrorToList(fileName, message) {
        this.fileErrors.push(fileName + " : " + message);
    }
}
