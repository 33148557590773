import { Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class RestApi {

   
	constructor(private httpClient: HttpClient) {}

	public getConfigInfos() {
	    return this.httpClient.get<Object[]>('/load-configuration');
	}
	
	public checkCaptcha(captchaText) {
	    return this.httpClient.get<Object[]>('/check-captcha?captchaText=' + captchaText);
	}
	
	public submitForm(formData) {
        return this.httpClient.post('/upload', formData);
    }
}