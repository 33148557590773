import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class SubmitTimeStoreService {

    submitTimes : Date[] = new Array(); 
    
    public addSubmitTime() {
        this.submitTimes.push(new Date());
    }
    
    public isNumberOfFreeSubmitsExceeded() {
        if (this.submitTimes.length < 3) {
            return false;
        }
        
        var dateMax = new Date();
        dateMax.setTime(dateMax.getTime() - (10 * 60 * 1000));
        console.log("Date Max: " + dateMax);
        
        var exceedCount = 0;
        // Count the number of timeStamps of the last 10 minutes
        this.submitTimes.forEach(date => {
           if (date.getTime() > dateMax.getTime()) {
               exceedCount++;
           } 
        });
        
        console.log("exeeded count: " + exceedCount);
        return exceedCount >= 3;
    }
}