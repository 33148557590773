import { Component, OnInit, ViewChild } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { ConfigurationDataModel } from '../app/models/configuration-dataModel';
import { RestApi } from '../services/RestApi';
import { SubmitTimeStoreService } from '../services/submit-time-store.service';


import { FileUploadComponent } from './file-upload/file-upload.component';
import { UploadData } from './models/upload-data';
import { NgForm } from "@angular/forms/src/directives";

import { ToastrService } from 'ngx-toastr'; 
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

    @ViewChild(FileUploadComponent)
    private fileUploadComponent: FileUploadComponent;
    
    configData: any[];
    // config properties
    HINT_TEXT: String;
    INVESTIGATION: String;
    AGB_INFO: String;
    CAPTCHA_ENABLE: String;
    ACTIVE: boolean;

    // upload properties
    UPLOAD_INFO: String;
    FILE_EXT_WHITELIST : String;
    UPLOAD_FILES_MAX : String;
    UPLOAD_FILE_MAX_SIZE : String;
    UPLOAD_FILE_MAX_SIZE_TOTAL : String;
    VIRUS_INFO_MSG: String;
    UPLOAD_ERR_MSG: String;
    UPLOAD_FILE_MANDATORY : String;
    
    configLoaded : boolean;
    captchaText : String = '';
    captchaEnabled : boolean = false;
    uploadQueueEmpty : boolean = true;
    agbChecked : boolean = false;
    errorCount : number = 0; 
    fileCount  : number = 0; 
    htmlDescriptionMinLength : number = 0;
    
    uploadData: UploadData;

    
    constructor( private translate: TranslateService,
        private restApi: RestApi,
        private submitTimeStoreService: SubmitTimeStoreService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService ) { }

    ngOnInit() {
        this.translate.setDefaultLang( 'de' );
        this.getConfigData();
        this.uploadData = {} as UploadData;
    }

    public selectLang( lang ) {
        this.translate.use( lang );
        this.translateConfigData();
    }

    private translateConfigData() {
        let currentLang = this.translate.currentLang;
        if ( currentLang === 'de' ) {
            this.UPLOAD_INFO = this.configData['UPLOAD_INFO_GERMAN'];
            this.HINT_TEXT = this.configData['HINT_TEXT_GERMAN'];
            this.INVESTIGATION = this.configData['INVESTIGATION_GERMAN'];
            this.VIRUS_INFO_MSG = this.configData['VIRUS_INFO_MSG_GERMAN'];
            this.UPLOAD_ERR_MSG = this.configData['UPLOAD_ERR_MSG_GERMAN'];
            this.AGB_INFO = this.configData['AGB_INFO_GERMAN'];
        }
        else if ( currentLang === 'en' ) {
            this.UPLOAD_INFO = this.configData['UPLOAD_INFO_ENGLISH'];
            this.HINT_TEXT = this.configData['HINT_TEXT_ENGLISH'];
            this.INVESTIGATION = this.configData['INVESTIGATION_ENGLISH'];
            this.VIRUS_INFO_MSG = this.configData['VIRUS_INFO_MSG_ENGLISH'];
            this.UPLOAD_ERR_MSG = this.configData['UPLOAD_ERR_MSG_ENGLISH'];
            this.AGB_INFO = this.configData['AGB_INFO_ENGLISH'];
        }
    }

    private getConfigData() {
        this.restApi.getConfigInfos().subscribe(
            ( response: any ) => {
                this.configData = response
                this.UPLOAD_INFO = this.configData['UPLOAD_INFO_GERMAN'];
                this.HINT_TEXT = this.configData['HINT_TEXT_GERMAN'];
                this.INVESTIGATION = this.configData['INVESTIGATION_GERMAN'];
                this.VIRUS_INFO_MSG = this.configData['VIRUS_INFO_MSG_GERMAN'];
                this.UPLOAD_ERR_MSG = this.configData['UPLOAD_ERR_MSG_GERMAN'];
                this.AGB_INFO = this.configData['AGB_INFO_GERMAN'];
                this.ACTIVE = ( this.configData['ACTIVE'] == 'true' );

                this.FILE_EXT_WHITELIST = this.configData['FILE_EXT_WHITELIST'];
                this.UPLOAD_FILES_MAX = this.configData['UPLOAD_FILES_MAX'];
                this.UPLOAD_FILE_MAX_SIZE = this.configData['UPLOAD_FILE_MAX_SIZE'];
                this.UPLOAD_FILE_MAX_SIZE_TOTAL = this.configData['UPLOAD_FILE_MAX_SIZE_TOTAL'];

                this.CAPTCHA_ENABLE = this.configData['CAPTCHA_ENABLE'];
                this.UPLOAD_FILE_MANDATORY = this.configData['UPLOAD_FILE_MANDATORY'];
                
                this.configLoaded = true;
                
                if (this.UPLOAD_FILE_MANDATORY != 'true') {
                    this.htmlDescriptionMinLength = 10;
                }
            },
            error => {
                this.translate.get( 'global_error' ).subscribe( text => {
                    this.toastr.error( text );
                } );
            } );
  }

    public submitForm( uploadForm: NgForm ) {
        console.log( "submitForm..." )

        if ( this.captchaEnabled == true ) {
            this.restApi.checkCaptcha( this.captchaText ).subscribe(( response: any ) => {
                let isCaptchaCorrect = response;
                console.log( "check captcha " + this.captchaText + " - result: " + isCaptchaCorrect );
                if ( isCaptchaCorrect == true ) {
                    this.submitDataToServer( uploadForm );
                }
                else {
                    uploadForm.controls['captchaInput'].setErrors( { 'captchaInvalid': true } );
                    uploadForm.controls['captchaInput'].markAsTouched();
                    uploadForm.controls['captchaInput'].markAsDirty();
                }
            },
                error => {
                    this.translate.get( 'global_error' ).subscribe( text => {
                        this.toastr.error( text );
                    } );
                } );
        }
        else {
            this.submitDataToServer( uploadForm );
        }
  }

  private submitDataToServer(uploadForm: NgForm) {
      console.log("submit form data...");
      this.errorCount = 0;
      this.fileCount = this.fileUploadComponent.uploader.queue.length;
      
      // show spinner
      this.spinner.show();
      
      let formData = new FormData();
      formData.append('uploadData', JSON.stringify(this.uploadData));
      
      for (const element of this.fileUploadComponent.uploader.queue) {
        let fileItem = element._file;
        console.log("file to upload: " + fileItem.name );
        formData.append( 'file', fileItem );
      }

      this.restApi.submitForm(formData).subscribe(
              data => { 
                  if(Array.isArray(data)) {
                      // file errors list
                      var self = this;
                      data.forEach(function (error) {
                          self.errorCount++;
                          console.log(error.errorCode);
                          if (100 == error.errorCode)
                              self.fileUploadComponent.addFileErrorToList(error.fileName, self.VIRUS_INFO_MSG);
                          else if (900 == error.errorCode)
                              self.fileUploadComponent.addFileErrorToList(error.fileName, self.UPLOAD_ERR_MSG);
                      }); 
                  }
                  
                  if (this.UPLOAD_FILE_MANDATORY != 'true' || this.fileCount > this.errorCount) {
                      
                      this.translate.get('successText').subscribe(text =>{
                          this.toastr.success(text, null, {
                              disableTimeOut: true,
                              positionClass : 'toast-center-center',
                              closeButton : true
                          });
                      });   
                  }
                 
                  // add submit time
                  this.submitTimeStoreService.addSubmitTime();
                  // rest form
                  this.resetForm(uploadForm);  
                  // hide spinner
                  this.spinner.hide();
              }, 
              error => {
                  this.spinner.hide();
                  this.translate.get('sendFormExceptionMsg').subscribe(text =>{
                      this.toastr.error(text);
                  });     
              }      
      );
      
  }
  
  
  public resetForm( uploadForm: NgForm ) {
      this.fileUploadComponent.clearQueue();
      uploadForm.resetForm();
  }

  public showCaptcha() {
      if ( this.CAPTCHA_ENABLE == 'true' && this.submitTimeStoreService.isNumberOfFreeSubmitsExceeded() ) {
          this.captchaEnabled = true;
      }
      else {
          this.captchaEnabled = false;
      }
      return this.captchaEnabled;
  }

  public releodCaptcha() {
      var captchaImage = document.getElementById( "captchaImage" ) as HTMLImageElement;
      captchaImage.src = "/SimpleCaptcha.jpg?" + new Date().getTime();
  }


  public processUploadQueueChanges() {
      console.log( "processUploadQueueChanges... : " + this.fileUploadComponent );
      if ( this.fileUploadComponent ) {
          console.log( "fileUploadComponent..." );
          this.uploadQueueEmpty = this.fileUploadComponent.isQueueEmpty();
      }
      else {
          this.uploadQueueEmpty = true;
      }
      console.log( "uploadQueueEmpty : " + this.uploadQueueEmpty );
  } 
  
  public isSendFormBtnEnabled(uploadForm: NgForm) {
      var result = false;
      if (!uploadForm.invalid) {
          // wenn Dateien vorhanden sind, upload immer erlauben
          if (!this.uploadQueueEmpty) {
              result = true;
          } 
          // keine Dateien vorhanden
          else if (this.UPLOAD_FILE_MANDATORY == 'false' && uploadForm.value.htmlDescription && uploadForm.value.htmlDescription.length >= this.htmlDescriptionMinLength) {
              result = true;
          }
      }
      return result;
  }
}
