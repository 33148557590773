import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr'; 
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(
            private toastr: ToastrService,
            private translate: TranslateService,) 
            {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log(">> HTTP Interceptor: " + request.url);
        
        var username = 'tocken';
        var password = 'NV21z7SzMtOB27HWwLK1';
        var headers = new HttpHeaders( { Authorization: 'Basic ' + btoa( username + ':' + password ) } );
        
        request = request.clone({headers});
        
        return next.handle(request).pipe(
                map((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        console.log('http response --->>>', event);
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    console.log('http response error --->>>', error);                                   
                    return throwError(error.message);
                })
                
        
        );
        
    }
}